import React from "react"

class MRemoteFooter extends React.Component {
  constructor(props) {
    super(props)
    this.initTryCount = 0
  }

  init = () => {
    if (!window.initPersonalisation) {
      console.error("'initPersonalisation' missing")
      if (this.initTryCount < 50) {
        this.initTryCount++
        window.setTimeout(this.init.bind(this), 1)
        return
      } else {
        console.error(
          "Unable to render footer! 'initPersonalisation' not found. Is personalization.js loaded?"
        )
        return
      }
    }
    if (!window.PersonalisationRenderer) {
      window.initPersonalisation()
      if (!window.PersonalisationRenderer) {
        console.error(
          "Unable to render header since script did not provide PersonalisationRenderer"
        )
        return
      }
    }
    window.PersonalisationRenderer.footer()
  }

  /* Never update, since contents are rendered by personalization */
  shouldComponentUpdate() {
    return false
  }

  componentDidMount() {
    console.log("mount lz-public footer")
    this.init()
  }

  componentDidUpdate() {
    console.log("update lz-public footer")
  }

  render() {
    return (
      <footer
        className="m-Footer"
        data-localfootersitemapurl={`${process.env.PUBLIC_CDN_HOST}${
          process.env.FOOTER_JSON_PATH
        }`}
        data-cdnabbinderurl={`${process.env.PUBLIC_CDN_HOST}${
          process.env.FOOTER_META_JSON_PATH
        }`}
      >
        <div className="m-Footer-wrapper">
          <div className="m-Footer-container">
            <div className="m-Footer-directory" />
          </div>
        </div>
      </footer>
    )
  }
}

export default MRemoteFooter
