import React from "react"

class MHeader extends React.Component {
  constructor(props) {
    super(props)
    this.className = "m-Header"
    if (!props.transparent) {
      this.className += " m-Header--darkGreen"
    }
    this.initTryCount = 0
  }

  /* Never update, since contents are rendered by personalization */
  shouldComponentUpdate() {
    return false
  }

  initHeader = () => {
    if (!window.initPersonalisation) {
      if (this.initTryCount < 50) {
        this.initTryCount++;
        window.setTimeout(this.initHeader.bind(this), 1)
        return
      } else {
        console.error("Unable to render header! 'initPersonalisation' not found. Is personalization.js loaded?")
        return
      }
    }
    if (!window.PersonalisationRenderer) {
      window.initPersonalisation()
      if (!window.PersonalisationRenderer) {
        console.error("Unable to render header since PSS script did not provide PersonalisationRenderer")
        return
      }
    }
    new window.PersonalisationRenderer.HeaderRenderer(
      `${process.env.PUBLIC_CDN_HOST}${process.env.HEADER_JSON_PATH}`,
      window.PersonalisationRenderer.config
    ).render()
  }


  componentDidMount() {
    this.initHeader();
  }

  render() {
    return (
      <header
        className={this.className}
      />
    )
  }
}

export default MHeader
